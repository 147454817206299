import { useCallback } from 'react';

/**
 * This must remain as a composition hook to avoid circular dependencies
 * and invalid context access
 */
import { useAuth as useAuthStore } from '@reckon-web/auth-store';

import { useNavigateToPortal } from '../Navigation/useNavigateToPortal';

import { useAuthenticatedUser } from './useAuthenticatedUser';

export function useAuth() {
  const authStore = useAuthStore();
  const authenticatedUser = useAuthenticatedUser();
  const portalNavigation = useNavigateToPortal(
    authenticatedUser.user?.tenancyId
  );

  /**
   * Logout the user and redirect to the login page
   *
   * This callback exists as a composition here instead of in the AuthProvider
   * because:
   * - logging out needs user tenancy information, which we wont' have access to in
   * the AuthProvider scope (gql client not available there).
   * - we need to redirect to the correct login page based on the user tenancy
   */
  const handleLogout = useCallback(() => {
    authStore.logout();
    portalNavigation.navigateToLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser.user?.tenancyId]);

  return {
    ...authStore,
    logout: handleLogout,
  };
}
